import styled from "styled-components";
import { NavLink as RouterLink } from "react-router-dom";

export const CloseIconWrapper = styled.div`
    margin-bottom: 60px;
`;

export const CloseIcon = styled.img`
    width: 24px;
    height: 24px;
    float: right;
`;

export const Container = styled.div`
    padding-bottom: 50px;
    border-bottom: 2px solid #F5FBFC;
`;

export const ProfileContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    align-items: center;
`;

export const ProfileIcon = styled.img`
    width: 36px;
    height: 36px;
`;  

export const ProfileDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const ProfileName = styled.div`
    font-size: 16px;
    color: #000000;
`;

export const ProfileNumber = styled.div`
    font-size: 13px;
    color: #808080;
`;

export const AddButton = styled.button`
    padding: 10px;
    width: 100%;
    height: 44px;
    background: #7aba56 0% 0% no-repeat padding-box;
    border-radius: 8px;
    color: #ffffff;
    margin-top: 50px;
    outline: none;
    border-style: hidden;
    font-size: 16px;
    cursor: pointer;
`;

export const NavLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  height: 42px;
  color: #808080;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  padding-left: 10px;
  gap: 10px;
  margin: 0 16px;
  justify-content: flex-start;

  &.active {
    background-color: #F5FBFC;
    border-radius: 8px;
    color: #0099cc;
  }
`;

export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #0099CC;
  cursor: pointer;
`;

export const ArrowBackImage = styled.img`
`;

export const PatientProfileIcon = styled.img`
  width: 48px;
  height: 48px;
  margin: 24px;
`;;
export const PatientName = styled.div`
  font-size: 16px;
  color: #0099CC;
  margin-bottom: 5px;
  margin-left: 24px;
`;
export const PhoneNumber = styled.div`
  font-size: 20px;
  color: #000000;
  margin-bottom: 16px;
  margin-left: 24px;
`;
export const SidebarImage = styled.img`
  margin-right: 10px;
`;
export const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  margin-left: 24px;
`;
export const DetailItemDob = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 56px;
  margin-left: 24px;
`;
export const GenderImage = styled.img`
`;
export const DobImage = styled.img`
  width: 16px;
  height: 16px;
`;
export const GenderText = styled.div`
  color:#808080;
  font-size: 16px;
`;
export const DobText = styled.div`
  color:#808080;
  font-size: 16px;
`;
export const DobDate = styled.span`
`;
export const DobYears = styled.span`
  color: #808080;
`;
export const NextArrowIcon = styled.img`
    cursor: pointer;
    width: 24px;
    height: 24px;
    flex: 1;
`;

export const EmailText = styled.div`
    font-size: 14px !important;
    color: #000000;
    max-width: 210px;   
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;    
`;

export const EmailItem = styled.div`
display: flex;
align-items: start;
gap: 8px;
margin-top: 3px;
`;

export const EmailImage = styled.img`
  height: 14px;
  margin-top: 4px;
`;