import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { redArrowDownward, greenArrowDownward } from "../../../assets";

const StatusDropdown = ({
  handleChange,
  selectedStatus,
  dropdownOptions,
  disabled = false,
}) => {
  const isRestricted = selectedStatus === "RESTRICTED";

  return (
    <FormControl>
      <Select
        value={selectedStatus}
        onChange={handleChange}
        disabled={disabled}
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 0,
          width: "215px",
          height: "45px",
          color: isRestricted ? "#CC8552" : "#7ABA56",
          backgroundColor: "#FFF",
          borderRadius: "5px",
          border: `1px solid ${isRestricted ? "#CC8552" : "#7ABA56"}`,
          "& .MuiSelect-icon": {
            color: isRestricted ? "#CC8552" : "#7ABA56",
            backgroundImage: `url(${
              isRestricted ? redArrowDownward : greenArrowDownward
            })`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "24px",
            height: "24px",
            right: "10px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: isRestricted ? "#CC8552" : "#7ABA56",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: isRestricted ? "#CC8552" : "#7ABA56",
          },
        }}
        IconComponent={() => (
          <img
            src={isRestricted ? redArrowDownward : greenArrowDownward}
            alt="dropdown icon"
            style={{ width: "24px", height: "24px" }}
          />
        )}
      >
        {dropdownOptions.map((option) => (
          <MenuItem
            key={`dropdown-${option.value}`}
            value={option.value}
            sx={{ width: "215px" }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusDropdown;
