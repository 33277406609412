import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Modal,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useDispatch, useSelector } from "react-redux";
import { ICloseModal } from "../../../assets";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { Bars } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";
import {
  uploadAudioAction,
  startTranscriptionJobAction,
  getNotesAudioAction,
  getOpenAITranscriptionAction,
} from "../../../store/actions";
import { uploadAudioSelector } from "../../../store/slice/upload-audio.slice";
import { startTranscriptSelector } from "../../../store/slice/start-transcript.slice";
import { getNotesAudioSelector } from "../../../store/slice/get-notes.slice";
import {
  IMicIcon,
  PlayerStop,
  PlayerSave,
  Playerplays,
  WaveFrom,
} from "../../../assets";
import {
  ModalBody,
  ModalHeader,
  CloseButtonWrapper,
  CloseButton,
  SaveButton,
  CopySummaryButton,
  FinishButton,
  LoaderWrapper,
  TextAreaWrapper,
  Label,
  TextArea,
  LabelTextArea,
  ModalBodySecond,
  StyledMinimizeIcon,
  StyledSelect,
  StartTranscriptionContainer,
  FromText,
  EHRContainer,
  SideIcon,
  TranscriptionextArea,
  AddToEhrButton,
  PreviewAudioSection,
  Transcribe,
  CustomRecordBox,
} from "./style.components";
import { Loader } from "../../shared";
import { exit } from "process";
import { apiClient } from "../../../api/api-client";
import { API_ENDPOINT } from "../../../utilities/constants";
import { patientSelector } from "../../../store/slice/patient.slice";
import axios from "axios";
import RecordRTC from "recordrtc";
import StopIcon from "@mui/icons-material/Stop";
import MicIcon from "@mui/icons-material/Mic";
import { LiveAudioVisualizer } from "react-audio-visualize";

const SAMPLE_RATE = 48000;

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "80vh",
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
  "@media(max-width:991px)": {
    width: "85%",
  },
  "@media(max-width:768px)": {
    width: "95%",
  },
};

var mediaRecorder = null; // set mediaRecorder as  an globally accessible
var audioText;
const DG_KEY = "a0cfd002c33bb168ce45a0e9e5e05c6da88802fb";
let socket; // Declare socket globally

const AudioRecordModal = ({
  open,
  handleClose,
  handleSave,
  setPhysicalExamination,
  setOtherNotes,
  setSymptoms,
  setPatientHistory,
  patientHistory,
  symptoms,
  otherNotes,
  physicalExamination,
  setIsTranscriptionProgress,
  isTranscriptionProgress,
  setTranscriptionResponse,
  postTranscriptCallback,
}) => {
  const dispatch = useDispatch();
  const [audioUrl, setAudioUrl] = useState(null);
  const [showAudio, setShowAudio] = useState(false); // State to control rendering of the audio element
  const [audioBlobWhisper, setAudioBlobWhisper] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [jobId, setJobId] = useState("");
  const [getNotesObject, setGetNotesObject] = useState({});
  const [isPolling, setIsPolling] = useState(false);
  const [openAITranscription, setOpenAITranscription] = useState(false);
  const [isUploadingAudio, setIsUploadingAudio] = useState(false);
  const [transcriptionService, setTranscriptionService] = useState("OpenAI");
  const [isStartRecording, setStartRecording] = useState(true);
  const audioC = document.getElementById("audioElement");
  const [stop, setStop] = useState(false);
  const [chunkData, setChunkData] = useState([]);
  const ffmpegRef = useRef(new FFmpeg());
  const [loaded, setLoaded] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [timer, setTimer] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [addToEhrloading, setAddToEhrloading] = useState(false);
  const navigate = useNavigate();
  const increment = useRef(null);
  const { selectedPatientId } = useSelector(patientSelector);
  const playpauseButtonRef = useRef(null);
  const [isRecordingPaused, setIsRecordingPaused] = useState(false);
  const [inputDevice, setInputDevice] = useState("");
  const [recording, setRecording] = useState(false);
  const [finalTranscript, setFinalTranscript] = useState("");
  const [partialTranscript, setPartialTranscript] = useState("");
  const audioStreamRef = useRef();
  const recorderRef = useRef();
  const socketRef = useRef();
  const divRef = useRef(null);
  const [record, setRecord] = useState(false);
  const [text, setText] = useState("");
  const [blobURL, setBlobURL] = useState("");
  const [audioRef, setAudioRef] = useState(null); // Store audio element reference
  const [audioChunks, setAudioChunks] = useState([]);
  const [file, setFile] = useState(null);
  const [patientID, setPatientID] = useState();
  const location = useLocation();
  const [addToEhrLoading, setAddToEhrLoading] = useState(false);
  const [btnActive,setBtnActive] = useState(false)
  let currentText = ""; // if you want record all audio even if you stoped and restart MediaRecorder, so you should set it as a global variable
  const recorder = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    listAudioDevices();
  }, []);

  const startRec = () => {
    setRecord(true);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500); // Simulate starting recording after 2 seconds
    recorder.startRecording();
    setText("");
    setBlobURL("");
    setAudioChunks([]);
  };

  const stopRec = async () => {
    if (record && mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();

      // Stop all tracks of the media stream
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      recorder.stopRecording();
    }
    // Close the WebSocket connection if it exists
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.close();
    }
    setRecord(false);
    const blob = new Blob(audioChunks, { type: "audio/mp4" });
    const url = URL.createObjectURL(blob);
    setBlobURL(url);
    setAudioChunks([]); // Reset audioChunks here as well
    

    // Stop the audio if it's playing and reset its state
  if (audioRef && !audioRef.paused) {
    audioRef.pause();        // Pause the audio playback
    audioRef.currentTime = 0; // Reset the audio to the beginning
  }

  // Unload the audio source to remove the audio icon
  if (audioRef) {
    audioRef.removeAttribute("src"); // Remove the source
    audioRef.load(); // Reset the audio element to unload the source
  }
  
  };

  const deepGramAudio2text = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      let supportedMimeType = null;
      const mimeTypes = [
        "audio/webm",
        "audio/ogg",
        "audio/wav",
        "audio/mp4",
        "audio/mpeg",
      ];

      for (let mimeType of mimeTypes) {
        if (MediaRecorder.isTypeSupported(mimeType)) {
          supportedMimeType = mimeType;
          break;
        }
      }

      if (!supportedMimeType) {
        return alert("No supported MIME type found.");
      }

      // Proceed with the recording setup
      mediaRecorder = new MediaRecorder(stream, {
        mimeType: supportedMimeType,
      });

      const url = `wss://api.deepgram.com/v1/listen?model=nova-2-general&smart_format=true&language=hi`;
      socket = new WebSocket(url, ["token", DG_KEY]);

      socket.onopen = () => {
        if (!mediaRecorder) {
          console.error("MediaRecorder not initialized");
          return;
        }

        mediaRecorder.addEventListener("dataavailable", async (event) => {
          if (event.data.size > 0 && socket.readyState === 1) {
            socket.send(event.data);
            setAudioChunks((prevChunks) => [...prevChunks, event.data]);
          }
        });

        mediaRecorder.start(1100);
        console.log("Recording started");
      };

      socket.onmessage = async (message) => {
        try {
          const received = JSON.parse(message.data);

          if (
            received.channel &&
            received.channel.alternatives &&
            received.channel.alternatives.length > 0
          ) {
            const transcript = received.channel.alternatives[0].transcript;

            if (transcript && received.is_final) {
              currentText = currentText.concat(" " + transcript);
              audioText = currentText;
              console.log("Current text:", audioText);
              setText(audioText);
            }
          }
        } catch (error) {
          console.error("Error processing message:", error);
        }
      };
    });
  };

  useEffect(() => {
    if (record) {
      deepGramAudio2text();
    }
  }, [record]);

  async function listAudioDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
  
      const audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      if (audioInputDevices.length === 0) {
        console.log("No audio input device found");
        return;
      }
      const defaultDevice = audioInputDevices[0].deviceId;
      setInputDevice(defaultDevice);
    } catch (error) {
      console.error("Error listing audio devices:", error);
    }
  }

  const handleStart = () => {
    setTimer(0);
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(increment.current);
    setIsPaused(false);
  };

  const handleResume = () => {
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handleReset = () => {
    setTimer(0);
    clearInterval(increment.current);
  };

  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  useEffect(() => {
    if (fileUrl) {
      const payload = {
        s3_file_url: fileUrl,
      };
      dispatch(startTranscriptionJobAction(payload)).then((actionResult) => {
        const newJobId = actionResult.payload?.data?.job_id;
        if (newJobId) {
          setJobId(newJobId);
        }
      });
    }
  }, [fileUrl, dispatch]);

  useEffect(() => {
    const fetchTranscriptionResults = async () => {
      if (jobId && !isPolling) {
        setIsPolling(true);
        const actionResult = await dispatch(
          getNotesAudioAction({ jobId: jobId })
        );
        const responseData = actionResult.payload?.data;

        if (responseData === "pending") {
          setTimeout(fetchTranscriptionResults, 15000);
        } else {
          setIsPolling(false);
          setIsTranscriptionProgress("finished");
          setGetNotesObject(responseData);
          if (responseData?.clinical_data) {
            setPhysicalExamination(
              responseData.clinical_data.HISTORY_OF_PRESENT_ILLNESS.join("\n")
            );
            setOtherNotes(
              [
                ...responseData.clinical_data.ASSESSMENT,
                ...responseData.clinical_data.PLAN,
              ].join("\n")
            );
            setSymptoms(
              [
                ...responseData.clinical_data.PAST_MEDICAL_HISTORY,
                ...responseData.clinical_data.CHIEF_COMPLAINT,
                ...responseData.clinical_data.REVIEW_OF_SYSTEMS,
              ].join("\n")
            );
            // setPatientHistory(responseData.clinical_data.PAST_MEDICAL_HISTORY.join('\n'));
          }
          if (responseData?.transcript_data) {
            const formattedTranscription = responseData?.transcript_data
              .map((text) => text.split(",").join("\n"))
              .join("\n");
            setTranscriptionResponse(formattedTranscription);
          }
        }
      }
    };

    fetchTranscriptionResults();

    return () => {
      setIsPolling(false);
    };
  }, [jobId, dispatch]);

  useEffect(() => {
    if (open) {
      setAudioUrl(null);
    }
  }, [open]);

  useEffect(() => {
    console.log("isRecording", isStartRecording);
    if (!isStartRecording) {
      startRecording();
      handleStart();
    }

    return () => {
      stopRecording();
    };
  }, [isStartRecording]);

  const addAudioElement = async (blob) => {
    setChunkData([...chunkData, blob]);
    const url = URL.createObjectURL(blob);
    setAudioUrl(url);
  };

  const copySymptomsToClipboard = () => {
    navigator.clipboard
      .writeText(symptoms)
      .then(() => {
        console.log("Symptoms copied to clipboard!");
        toast.success("Symptoms copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy symptoms: ", err);
        toast.error("Failed to copy symptoms!");
      });
  };

  const copyPhysicalExaminationToClipboard = () => {
    navigator.clipboard
      .writeText(physicalExamination)
      .then(() => {
        console.log("Physical Examination copied to clipboard!");
        toast.success("Physical Examination copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy physical examination: ", err);
        toast.error("Failed to copy physical examination!");
      });
  };

  const copyOtherNotesToClipboard = () => {
    navigator.clipboard
      .writeText(otherNotes)
      .then(() => {
        console.log("Other Notes copied to clipboard!");
        toast.success("Other Notes copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy other notes: ", err);
        toast.error("Failed to copy other notes!");
      });
  };

  const onFinishHealthScribe = () => {
    setIsTranscriptionProgress("start");
    handleClose();
  };

  const getTranscriptionData = async (url) => {
    setIsTranscriptionProgress("inProgress");

    const responseTranscription = await axios.get(url, {
      headers: {
        "x-gladia-key": "5161c10e-3029-43e6-bcb9-7bc6727fd090",
        "Content-Type": "application/json",
      },
    });
    if (responseTranscription.data.status === "done") {
      setIsTranscriptionProgress("finished");
      console.log(
        "transcription for file upload responseTranscription finish",
        responseTranscription
      );
      setFinalTranscript(
        responseTranscription?.data?.result?.transcription?.full_transcript
      );
      return responseTranscription;
    } else {
      getTranscriptionData(url);
      return responseTranscription;
    }
  };

  const startTranscription = async () => {
    console.log("======strat transcription", audioBlobWhisper);
    if (audioBlobWhisper) {
      const url = URL.createObjectURL(audioBlobWhisper);
      console.log("======strat transcription 2", url);

      if (true) {
        setIsUploadingAudio(true);
        const formData = new FormData();
        formData.append("audio", audioBlobWhisper);
        try {
          //   const actionResult = await dispatch(uploadAudioAction(formData));
          const responseData = await axios.post(
            "https://api.gladia.io/v2/upload",
            formData,
            {
              headers: {
                "x-gladia-key": "5161c10e-3029-43e6-bcb9-7bc6727fd090",
              },
            }
          );
          const newFileUrl = responseData?.data?.audio_url;

          console.log("newFileUrl", newFileUrl);
          if (newFileUrl) {
            setIsUploadingAudio(false);
            setIsTranscriptionProgress("inProgress");

            try {
              const response = await axios.post(
                "https://api.gladia.io/v2/transcription",
                { audio_url: newFileUrl },
                {
                  headers: {
                    "x-gladia-key": "5161c10e-3029-43e6-bcb9-7bc6727fd090",
                    "Content-Type": "application/json",
                  },
                }
              );
              if (response?.data?.result_url) {
                console.log("transcription for file upload", response);
                const responseTranscription = await getTranscriptionData(
                  response?.data?.result_url
                );
                console.log(
                  "transcription for file upload responseTranscription",
                  responseTranscription
                );
                if (responseTranscription.data.status === "done") {
                  setIsTranscriptionProgress("finished");
                  console.log(
                    "transcription for file upload responseTranscription finish",
                    responseTranscription
                  );
                  setFinalTranscript(
                    responseTranscription?.data?.result?.transcription
                      ?.full_transcript
                  );
                } else {
                  getTranscriptionData(response?.data?.result_url);
                }
              }
            } catch (error) {
              return Promise.reject(error);
            }
          }
        } catch (error) {
          console.error("Upload failed:", error);
        } finally {
          setIsUploadingAudio(false);
        }
      }
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    addAudioElement(file);
    setAudioBlobWhisper(file);
  };

  const stopRecording = () => {
    setRecording(false);
    setIsRecordingPaused(true);
    handleReset();
    setStop(true);
    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        recorderRef.current = null;
      });
    }

    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
      audioStreamRef.current = null;
    }

    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
    }
  };

  const pauseRecording = () => {
    if (recording === true) {
      setStop(true);
      recorderRef.current.pauseRecording();
      setIsRecordingPaused(true);
      clearInterval(playpauseButtonRef.current);
      handlePause();
      setRecording(false);
    } else {
      if (recorderRef.current) {
        setIsRecordingPaused(false);
        setStop(false);
        recorderRef.current.resumeRecording();
        handleResume();
        setRecording(true);
      } else {
        startRecording();
      }
    }
  };

  async function startRecording() {
    const gladiaUrl = "wss://api.gladia.io/audio/text/audio-transcription";
    const gladiaKey = "5161c10e-3029-43e6-bcb9-7bc6727fd090";

    socketRef.current = new WebSocket(gladiaUrl);

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: inputDevice
          ? {
              deviceId: { exact: inputDevice },
              echoCancellation: true,
              noiseSuppression: true,
            }
          : { echoCancellation: true, noiseSuppression: true },
      });
      audioStreamRef.current = mediaStream;

      const configuration = {
        x_gladia_key: gladiaKey,
        frames_format: "bytes",
        language_behaviour: "automatic single language",
        sample_rate: SAMPLE_RATE,
        desiredSampRate: SAMPLE_RATE,
      };

      socketRef.current.onopen = async () => {
        await socketRef.current.send(JSON.stringify(configuration));
        console.log("socket connected successfully!!!--@@@@--!!!");
        setRecording(true);
        setStop(false);

        ///geting response from socket
        socketRef.current.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data?.event === "transcript" && data.transcription) {
            if (data.type === "final") {
              setFinalTranscript((prev) => prev + data.transcription);
              setPartialTranscript("");
            } else {
              setPartialTranscript(data.transcription);
            }
          }
        };

        ///recording  setup  after socket is up
        recorderRef.current = await new RecordRTC(mediaStream, {
          type: "audio",
          mimeType: "audio/wav",
          recorderType: RecordRTC.StereoAudioRecorder,
          timeSlice: 1000,
          ondataavailable: async (blob) => {
            ///sending data to socket
            await socketRef.current.send(blob);
          },
          sampleRate: SAMPLE_RATE,
          desiredSampRate: SAMPLE_RATE,
          numberOfAudioChannels: 1,
        });
        recorderRef.current.startRecording();
        ////////////////
      };

      socketRef.current.onerror = () => {
        console.log("Couldn't connect to the server ther is something wrong");
        stopRecording();
      };
      socketRef.current.onclose = (event) => {
        console.log(`Server connection close: [${event.code}] ${event.reason}`);
        stopRecording();
      };
    } catch (error) {
      console.log(`Error_!!!!!!!!!: ${error.message || error}`);
      stopRecording();
    }
  }

  const sleep = (delay) => new Promise((f) => setTimeout(f, delay));
  const sendTranscript = async (data) => {
    try {
      setAddToEhrloading(true);
      const response = await apiClient.post(
        API_ENDPOINT.POST_TRANSCRIPT_SUMMARY,
        data,
        false
      );
      if (response) {
        postTranscriptCallback({
          infoResponse: response,
          transcript: text,
        });
        stopRecording();
        setAddToEhrloading(false);
        handleClose();
      }
      return response;
    } catch (error) {
      setAddToEhrloading(false);
      return Promise.reject(error);
    }
  };

  const handleBlur = () => {
    // Update the state with the content when the div loses focus
    setText(divRef.current.innerText);
  };

 // Function to save cursor position
const saveCaretPosition = (el) => {
  const selection = window.getSelection();
  if (selection.rangeCount === 0) return null; // No selection range
  const range = selection.getRangeAt(0);
  return {
    startOffset: range.startOffset,
    endOffset: range.endOffset,
  };
};

// Function to restore cursor position
const restoreCaretPosition = (el, positions) => {
  if (!positions) return; // Ensure positions are valid
  const selection = window.getSelection();
  const range = document.createRange();

  // Check if the element has child nodes and valid text content
  if (el.childNodes.length === 0 || !el.childNodes[0].nodeValue) return;

  // Ensure startOffset and endOffset do not exceed the node's text length
  const textLength = el.childNodes[0].nodeValue.length;
  const startOffset = Math.min(positions.startOffset, textLength);
  const endOffset = Math.min(positions.endOffset, textLength);

  range.setStart(el.childNodes[0], startOffset);
  range.setEnd(el.childNodes[0], endOffset);
  selection.removeAllRanges();
  selection.addRange(range);
};

  const handleInput = () => {
    const caretPosition = saveCaretPosition(divRef.current);

    const currentText = divRef.current.innerText;
    setText(currentText);

    // Activate the button only when there's at least one character
    if (currentText.trim() !== '') {
      setBtnActive(true);
    } else {
      setBtnActive(false);
    }

    setTimeout(() => {
      restoreCaretPosition(divRef.current, caretPosition);
    }, 0);
  };


  const renderRecorderPage = () => (
    <ModalBody>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "",
            overflowY: "auto", // Scroll if content overflows
          }}
        >
          <Box className="container">
            {recorder.mediaRecorder && !loading && record && (
              <LiveAudioVisualizer
                mediaRecorder={recorder.mediaRecorder}
                width={200}
                height={75}
              />
            )}
            <div className="real-time-interface">
              <p id="modal-modal-title">
                Click the button below and start speaking into your microphone
                to see your live transcription.
              </p>

              {loading ? (
                <div
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    marginTop: "3px",
                    display: "flex", // Use flexbox
                    alignItems: "center", // Align items vertically
                    justifyContent: "center", // Center content horizontally
                    gap: "10px", // Space between loader and text
                  }}
                >
                  <Bars
                    height="30"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="bars-loading"
                    visible={true}
                  />
                  <p
                    id="modal-modal-title"
                    style={{
                      color: "#000",
                      marginTop: "0px", // Space between loader and text
                      fontSize: "14px",
                    }}
                  >
                    Speak after loading stops...
                  </p>
                </div>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#7ABA56",
                    color: "white",
                    height: "45px",
                  }}
                  sx={{ color: "#FFF" }}
                  variant="contained"
                  onClick={record ? stopRec : startRec}
                  startIcon={record ? <StopIcon /> : <MicIcon />}
                >
                  {record ? "Stop recording" : "Start Talking"}
                </Button>
              )}
            </div>
            <br></br>
            {blobURL && (
              <div>
                <audio ref={(element) => setAudioRef(element)}  src={blobURL ? blobURL : ""} controls preload="metadata" />
              </div>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            padding: 2,
            backgroundColor: "",
            height: "300px", // Fixed height

            overflowY: "auto", // Scroll if content overflows
          }}
          className="container"
        >
          <div
            className="real-time-interface__message"
            contentEditable={true}
            suppressContentEditableWarning={true}
            ref={divRef}
            onKeyUp={handleInput}
            onBlur={handleBlur}
          >
            {text}
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2, // Space between text and buttons
            }}
          >
            <Button
              style={{
                backgroundColor: blobURL || btnActive ? "#7ABA56" : "#ccc", // Green when active, grey when disabled
                color: blobURL || btnActive ? "white" : "#666", // Light grey text when disabled
                marginRight: "10px",
              }}
              sx={{ color: "#FFF", height: "45px" }}
              variant="contained"
              disabled={!blobURL && !btnActive}
              onClick={() =>
              {

                sendTranscript({
                  transcript: text,
                  patient_id: selectedPatientId,
                })
                
              }
              }
            >
              {addToEhrloading ? (
                <CircularProgress size={24} color="inherit" /> // Show loader
              ) : (
                "Add to EHR"
              )}
            </Button>

            <Button
              style={{ backgroundColor: "#7ABA56", color: "white" }}
              sx={{ color: "#FFF", height: "45px" }}
              variant="contained"
              onClick={() => {
                stopRec();
                handleClose();
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Grid>
    </ModalBody>
  );

  const renderFinishedPage = () => (
    <ModalBodySecond>
      <TextAreaWrapper>
        <Label>Symptoms</Label>
        <LabelTextArea>
          <TextArea
            value={symptoms}
            onChange={(e) => setSymptoms(e.target.value)}
          />
          <CopySummaryButton onClick={copySymptomsToClipboard}>
            Copy
          </CopySummaryButton>
        </LabelTextArea>
      </TextAreaWrapper>
      <TextAreaWrapper>
        <Label>Physical Examination</Label>
        <LabelTextArea>
          <TextArea
            value={physicalExamination}
            onChange={(e) => setPhysicalExamination(e.target.value)}
          />
          <CopySummaryButton onClick={copyPhysicalExaminationToClipboard}>
            Copy
          </CopySummaryButton>
        </LabelTextArea>
      </TextAreaWrapper>
      <TextAreaWrapper>
        <Label>Other Notes</Label>
        <LabelTextArea>
          <TextArea
            value={otherNotes}
            onChange={(e) => setOtherNotes(e.target.value)}
          />
          <CopySummaryButton onClick={copyOtherNotesToClipboard}>
            Copy
          </CopySummaryButton>
        </LabelTextArea>
      </TextAreaWrapper>

      <EHRContainer>
        <FinishButton onClick={onFinishHealthScribe}>Add to EHR</FinishButton>
      </EHRContainer>
    </ModalBodySecond>
  );

  const shouldShowLoader = () => {
    return isTranscriptionProgress === "inProgress";
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CloseButtonWrapper>
          {isTranscriptionProgress === "inProgress" ? (
            <StyledMinimizeIcon
              onClick={() => {
                handleClose();
              }}
            />
          ) : (
            <CloseButton
              src={ICloseModal}
              onClick={() => {
                stopRec();
                handleClose();
              }}
            />
          )}
        </CloseButtonWrapper>
        <ModalHeader id="modal-modal-title">Audio Scribe</ModalHeader>
        <>
          {isUploadingAudio && (
            <LoaderWrapper>
              <Loader text="Uploading Audio..." />
            </LoaderWrapper>
          )}
          {renderRecorderPage()}
          {shouldShowLoader() && (
            <LoaderWrapper>
              <Loader text="Transcription In Progress" />
            </LoaderWrapper>
          )}
        </>
      </Box>
    </Modal>
  );
};

export default AudioRecordModal;
