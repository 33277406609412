import { Box, CardMedia } from "@mui/material";
import {
  CardDetailsWrapper,
  ListItemDate,
  ThumbImage,
  ReportType,
  ReportTypeWrapper,
  ContentType,
  ThumbImageWrapper,
} from "../../../containers/view-patient/patient-records/style.components";
import styled from "styled-components";
import DropdwonThreeDotMenu from "../DropdownThreeDot";
import { formatTimestamp } from "../../../utilities/common";
import { useSelector } from "react-redux";

const ListItemTopReport = styled.div`
  color: #404040;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 15px;
  flex: 1 1 calc(20% - 24px);
  max-width: 140px;
  padding-left: 5px;
`;

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  border: none;
  max-width: 160px;
  height: auto;
`;

const SingleReport = ({ name, icon, test_type, upload_date, id, status }) => {
  const thumbnails = useSelector((state) => state?.thumbnail?.thumbnails);

  return (
    <Box
      sx={{
        background: "#FFF",
        padding: "1rem",
        borderRadius: "1rem",
        border: "0.5rem solid #D7EFF7",
      }}
      display="flex"
      justifyContent="center"
    >
      <ListItemWrapper style={{ cursor: "auto" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mb={1}
        >
          <ListItemTopReport title={name}>{name}</ListItemTopReport>
          <DropdwonThreeDotMenu menuItems={[]} />
        </Box>
        <ThumbImageWrapper>
          <CardMedia
            component="img"
            height="120"
            image={(thumbnails && thumbnails[id]) || icon}
            alt={"records-thumbnail"}
            sx={{
              objectFit: "contain",
              padding: "10px 5px",
              boxSizing: "border-box",
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = icon;
            }}
          />
          <ReportTypeWrapper>
            <ReportType>{test_type || "REPORT"}</ReportType>
            <ContentType>PDF</ContentType>
          </ReportTypeWrapper>
        </ThumbImageWrapper>
        <CardDetailsWrapper>
          <ListItemDate>{formatTimestamp(upload_date)}</ListItemDate>
        </CardDetailsWrapper>
        {status === "RESTRICTED" && (
          <Box
            sx={{
              backgroundColor: "#CC8552",
              color: "#FFF",
              justifyContent: "start",
              cursor: "auto",
              padding: "0.5rem",
              paddingLeft: "0.8rem",
              borderRadius: "8px",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Restricted
          </Box>
        )}
      </ListItemWrapper>
    </Box>
  );
};

export default SingleReport;
