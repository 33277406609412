import styled from "styled-components";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select } from "@mui/material";

export const ParentContainer = styled.div`
    padding: 40px;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
`;

export const BackArrowImg = styled.img`
  width: 32px;
  height: 32px;
`;

export const BackText = styled.div`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const HeadText = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  margin-left: 20px;
`;

export const RecordsListContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

export const PreviewImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const RemoveButton = styled.button`
  background: #E07A5F;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
`;

export const CategoryDropdownStyled = styled(Select)`
  && {
    min-width: 135px;
    border-radius: 5px;
    border: 1px #92D4E9 !important;
  }
`;

// Styled MUI components
export const StyledTableContainer = styled(TableContainer)`
  && {
    margin: 20px 0;
  }
`;

export const StyledTable = styled(Table)`
  && {
    border-collapse: collapse;
    width: 100%;
  }
`;

export const StyledTableHead = styled(TableHead)`
  && {
  }
`;

export const StyledTableRow = styled(TableRow)`
  && {
    border-bottom: 1px solid #ddd;
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    font-size: 14px;
    text-align: left;
    padding: 16px;
  }
`;

export const StyledTableCellScans = styled(TableCell)`
&& {
  font-size: 14px;
  padding: 10px 12px;
  border-radius: 5px;
  background: #D7EFF7;
  min-width: 168px;
  border: none;
}
`;

export const StyledTableCellHead = styled(TableCell)`
  && {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 16px;
  }
`;

export const StyleTableName = styled.div`
  border-radius: 8px;
  border: 1px solid #92D4E9;
  padding: 10px 12px;
  background: #FFF;
  min-width: 168px;
`;

export const StyledTableCellImage = styled(TableCell)`
  && {
    margin-left: 8px;
    width: 140px;
    height: auto;
    background-color: #EBF7FB;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
  }
`;

export const StyledTableBody = styled(TableBody)`
  && {
    background-color: #fff;
  }
`;

export const DeleteIcon = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const NextButton = styled.button`
  height: 44px;
  background: #7ABA56;
  cursor: pointer;
  border-radius: 8px;
  color: #ffffff;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  margin-left: 20px;
  width: 220px;
  margin-top: 40px;
  float: right;

  &:disabled {
    background: #cccccc;
    cursor: not-allowed;
    color: #666666;
  }
`;
