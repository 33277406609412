import React, { useState, useEffect } from 'react';
import { IHemeCloudLogo, IHamburgerIcon,ILogoWhite,hamburgerMenuIcon  } from "../../../assets";
import ProfileDropdown from "./profile-dropdown";
import SideNav from "./side-nav";
import { LogoImage, LogoImageCont, StickyHeader, HamburgerIconCont } from "./style.components";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "../../../store/slice/auth.slice";

const FixedHeader = ({HamburgerMenuToggle}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { selectedCountry } = useSelector(authSelector);
  const { userDetails } = useSelector(authSelector);

  const isHome = location.pathname === "/" || location.pathname === "/home";

  useEffect(() => {
    const userName = `${userDetails?.first_name}${+ " " + userDetails?.last_name ? userDetails?.last_name : ""}`;
    //@ts-ignore
    window["SI_API"]?.hidePopup();
    //@ts-ignore
    window?.["setDetails"](
      userName || "",
      userDetails?.email || "",
      "Query? Let us know how can we help you?"
    )
  });

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <>
      <StickyHeader>
        <LogoImageCont>
          <LogoImage src={IHemeCloudLogo} onClick={() => navigate("/home")} />
        </LogoImageCont>
        {/* <FlagIcon
          src={selectedCountry?.icon}
          alt="selected_country"
          style={{ height: 20, marginRight: 20, paddingTop: '3px' }}
        /> */}
        <ProfileDropdown />
        {!isHome && (
          <HamburgerIconCont onClick={toggleSidebar}>
            <img src={IHamburgerIcon} alt="Menu" />
          </HamburgerIconCont>
        )}
        <SideNav isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
      </StickyHeader>
    </>
  );
};

export default FixedHeader;
