import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const getConsultations = async (payload) => {
  try {
    const url = `${API_ENDPOINT.PATIENT}/care_episodes`;
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const addConsultation = async (payload) => {
  try {
    const url = `${API_ENDPOINT.PATIENT}/care_episode`;
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const closeConsultation = async (payload) => {
  try {
    const url = `${API_ENDPOINT.PATIENT}/care_episode/close`;
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getEncounters = async (payload) => {
  try {
    const url = `${API_ENDPOINT.PATIENT}/encounters`;
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const addEncounter = async (payload) => {
  try {
    const url = `${API_ENDPOINT.PATIENT}/encounter`;
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const closeEncounter = async (payload) => {
  try {
    const url = `${API_ENDPOINT.PATIENT}/encounter/close`;
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getConsultationInfo = async ({ patient_id, consultation_id }) => {
  try {
    const url = `${API_ENDPOINT.CARE_EPISODE}?patient_id=${patient_id}&episode_of_care_id=${consultation_id}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export {
  getConsultations,
  addConsultation,
  closeConsultation,
  getEncounters,
  addEncounter,
  closeEncounter,
  getConsultationInfo
};
