import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BackButtonContainer,
  BackArrowImg,
  BackText,
  HeadText,
  RecordsListContainer,
  PreviewImage,
  StyledTableCellHead,
  CategoryDropdownStyled,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTableCellImage,
  DeleteIcon,
  NextButton,
  ParentContainer,
  StyleTableName
} from "./style.components";
import { Select, MenuItem, Box } from "@mui/material";
import { IBackArrow, IDeleteIcon } from "../../assets";
import StatusDropdown from "../../components/shared/StatusDropdown/StatusDropdown";
import { pollRecordsAction, sendRecordsAction } from "../../store/actions";
import { patientSelector } from "../../store/slice/patient.slice";
import { Loader, SnackBar } from "../../components/shared";
import { COLOR_VARIANT } from "../../utilities/constants";

const categoryOptions = [
  { label: "Report", value: "Report" },
  { label: "Prescription", value: "Prescription" },
  { label: "Medical Letter", value: "Medical Letter" },
];

const dropdownOptions = [
  { label: "Available", value: "AVAILABLE" },
  { label: "Restricted", value: "RESTRICTED" },
];

const SendRecords = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedPatientId } = useSelector(patientSelector);
  const { records, recordType, uppyResponse } = location.state || {};
  const [recordList, setRecordList] = useState(records || []);
  const [isLoading, setIsLoading] = useState(false);
  const [polling, setPolling] = useState(true);

  // Initialize a state array to track the selected status of each record
  const [selectedStatuses, setSelectedStatuses] = useState(
    records ? records.map(() => dropdownOptions[0].value) : []  // Default to the first option for all records
  );

  // Initialize a state array to track the selected category of each record
  const [selectedCategories, setSelectedCategories] = useState(records ? records.map(() => "") : []);  // Default to empty

  // Polling function to fetch records
  const getPollRecords = async () => {
    try {
      const response = await dispatch(pollRecordsAction(uppyResponse?.assemblyID));

      if (response && response?.payload?.assemblyid) {
        // Loop through the response files and update the selected category where the file name matches
        const updatedCategories = [...selectedCategories];
  
        response?.payload?.files.forEach((file) => {
          const recordIndex = recordList.findIndex(
            (record) => record?.converted_file_name === file.file_name
          );
          if (recordIndex !== -1) {
            updatedCategories[recordIndex] = file.classification_result?.record_type;
          }
        });
        setSelectedCategories(updatedCategories);
  
        // Stop polling once we get the desired data
        setPolling(false);
      }
    } catch (error) {
      console.error("Error fetching records:", error);
      setPolling(false); // Stop polling on error
    } finally {
      setPolling(false);
    }
  };
  

  // Start polling every 2 seconds until we get a valid response or polling fails
  useEffect(() => {
    if (polling) {
      const intervalId = setInterval(() => {
        getPollRecords();
      }, 2000);

      // Clear the interval when the component unmounts or polling stops
      return () => clearInterval(intervalId);
    }
  }, [polling]);

  if (!recordList || recordList.length === 0) {
    return <div style={{padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center', color: '#0099CC',
      marginTop: '200px'}}>No records found</div>
  }

  const handleBackClick = () => {
    window.history.back();
  };

  // Update the selected status for a specific record
  const handleStatusChange = (index) => (event) => {
    const newStatuses = [...selectedStatuses];
    newStatuses[index] = event.target.value;
    setSelectedStatuses(newStatuses);
  };

  // Update the selected category for a specific record
  const handleCategoryChange = (index) => (event) => {
    const newCategories = [...selectedCategories];
    newCategories[index] = event.target.value;
    setSelectedCategories(newCategories);
  };

  const handleRemoveRecord = (index) => {
    setRecordList(prevList => prevList.filter((_, i) => i !== index));
  };

  // Check if all records have a category selected
  const isAddButtonDisabled = selectedCategories.some((category) => category === "");

  const handleAddRecords = async () => {
    try {
      setIsLoading(true);
      let fileType;
      const reportInfo = uppyResponse?.uploadedFiles.map((file, index) => {
        const fileName = recordList[index]?.original_file_name;
        const s3FileUrl = recordList[index]?.file_gcp_location;
        fileType = selectedCategories[index].toLowerCase().replace(" ", "_");
        const status = selectedStatuses[index];

        return {
          file_id: file.file_id,
          mimetype: file.mimetype,
          file_size: file.file_size,
          file_name: fileName,
          file_type: fileType,
          s3_file_url: s3FileUrl,
          status: status,
        };
      });

      const payload = {
        patient_id: selectedPatientId,
        report_info: reportInfo,
      };

      const response = await dispatch(sendRecordsAction({ type: fileType, payload })).unwrap();
      console.log(response, 'sendrecords')
      if (response?.status === 'success') {
        setIsLoading(false);
        navigate(-1);
      }
    } catch (error) {
      console.error("Error adding records:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
    <ParentContainer>
      <BackButtonContainer onClick={handleBackClick}>
        <BackArrowImg src={IBackArrow} />
        <BackText>{`Back`}</BackText>
      </BackButtonContainer>
      <HeadText>Here is the system detected details, please confirm and edit as required</HeadText>
      {!isLoading ? <RecordsListContainer>
        <StyledTableContainer>
          <StyledTable>
            <StyledTableHead>
              <StyledTableRow>
                <StyledTableCellHead>Preview</StyledTableCellHead>
                <StyledTableCellHead>File Name</StyledTableCellHead>
                <StyledTableCellHead>Category</StyledTableCellHead>
                <StyledTableCellHead>Status</StyledTableCellHead>
                <StyledTableCellHead>Remove</StyledTableCellHead>
              </StyledTableRow>
            </StyledTableHead>
            <StyledTableBody>
              {recordList.map((record, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCellImage>
                    <PreviewImage src={record?.thumbnail_gcp_location} alt={record?.original_file_name} />
                  </StyledTableCellImage>
                  <StyledTableCell><StyleTableName>{record?.original_file_name}</StyleTableName></StyledTableCell>
                  <StyledTableCell>
                    <CategoryDropdownStyled
                      value={selectedCategories[index] || ""}
                      onChange={handleCategoryChange(index)} // Handle category change
                      displayEmpty // This enables the placeholder
                      input={<Select variant="outlined" fullWidth />}
                    >
                      <MenuItem value="" disabled>
                        Select Category
                      </MenuItem>
                      {categoryOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </CategoryDropdownStyled>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box mt={1} mb={1}>
                      <StatusDropdown
                        selectedStatus={selectedStatuses[index]}
                        handleChange={handleStatusChange(index)}
                        dropdownOptions={dropdownOptions}
                      />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <DeleteIcon src={IDeleteIcon} onClick={() => handleRemoveRecord(index)} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </StyledTableBody>
          </StyledTable>
        </StyledTableContainer>
        <NextButton disabled={isAddButtonDisabled} onClick={handleAddRecords}>Add Records</NextButton>
      </RecordsListContainer>
      : <Loader text={"Adding record......"} />
      }
    </ParentContainer>
    <SnackBar />
    </>
  );
};

export default SendRecords;
