  import React, { useEffect, useState } from "react";
  import { useNavigate, useLocation } from "react-router-dom";
  import { IBackArrow, ILabReportThumb, IBlueArrowRight } from "../../../../assets";
  import {
    FinishButton,
    AddButton,
    Container,
    Header,
    TopHeader,
    HeaderRight,
    HeaderWrapper,
    ProfileImage,
    CaseNumber,
    ArrowBackImage,
    ActionImage,
    EditButton,
    EditDeleteWrapper,
    EmptyListMessage,
    FullNameWrapper,
    UpdatedDate,
    StatusDropdown,
    CustomTableRow, 
    CaseNumberHead
  } from "./style.components";
  import { addEncounterAction, closeConsultationsAction, getEncountersAction, closeEncounterAction, getConsultationInfoAction } from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { patientSelector } from "../../../../store/slice/patient.slice";
import { Loader, SnackBar } from "../../../../components/shared";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";
import { format, parseISO } from 'date-fns';
import { ShimmerTable } from "react-shimmer-effects";
import Breadcrumb from "../../../../components/layout/Breadcrumb";
import { Select, MenuItem } from "@mui/material";

const PatientEncounters = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedPatientId } = useSelector(patientSelector);
  const [consultationId, setConsultationId] = useState(null);
  const [consultationStatus, setConsultationStatus] = useState(null);
  const [isCloseLoading, setIsCloseLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [encountersData, setEncountersData] = useState(null);
  const [isAddLoading, setIsAddLoading] = useState(false);

  const breadCrubLinks = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "Consulatations",
      onClick:  () => {
        navigate(`/patient-consultations?patient_id=${selectedPatientId}`);
      },
    },
    {
      label: `Case No. ${consultationId}`,
    },
  ];

  const location = useLocation();
  useEffect(() => {
    setConsultationId(location.state?.id);
  }, [location]);

  const fetchAllEncounters = async () => {
    if(selectedPatientId && consultationId){
      setIsLoading(true);
      const payload = {
        "patient_id": selectedPatientId,
        "episode_of_care_id": consultationId,
      }
      try {
        const response = await dispatch(getEncountersAction(payload));
        if(response?.payload.status === "success"){
          setEncountersData(response?.payload.data.encounters);
        }
      } catch (error) {
        console.error("Error fetching consultations:", error);
      } finally {
          setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchAllEncounters();
  },[dispatch, selectedPatientId, consultationId])

  const fetchConsultationInfo = async () => {
    if(selectedPatientId && consultationId){
      try {
        const response = await dispatch(getConsultationInfoAction({patient_id : selectedPatientId, consultation_id : consultationId}));
        if(response?.payload.status === "success"){
          setConsultationStatus(response?.payload.data.status);
        }
      } catch (error) {
        console.error("Error fetching consultations:", error);
      } finally {
          setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchConsultationInfo();
  },[dispatch, selectedPatientId, consultationId])

  const closeConsultation = async() => {
    if(selectedPatientId && consultationId) {
      setIsCloseLoading(true);
      const payload = {
        "patient_id": selectedPatientId,
        "episode_of_care_id": consultationId,
        "set_to_close": true
      }
      try{
        const response = await dispatch(closeConsultationsAction(payload));
        if(response.payload.status === "success"){
          navigate(-1);
        }
      } catch (error) {
        console.error("Error adding consultation:", error);
      } finally {
          setIsCloseLoading(false);
      }
    }
  }

  const addEncounter = async() => {
    if(selectedPatientId && consultationId){
      setIsAddLoading(true);
      const payload = {
        "patient_id": selectedPatientId,
        "episode_of_care_id": consultationId
      }
      try {
        const response = await dispatch(addEncounterAction(payload));
        if (response?.payload.status === "success"){
          setIsAddLoading(false)
          navigate("/new-encounter", {
            state: { id: response?.payload.data.id, status: response?.payload.data.status },
          })
        }
      } catch (error) {
        console.error("Error adding consultation:", error);
      } finally {
          setIsAddLoading(false);
      }
    }
  }

  const handleStatusChange = async (event, encounterId) => {
    const selectedStatus = event.target.value;
    const setToClose = selectedStatus === "closed"; // true if closed is selected, false if open
  
    const closeEncounterPayload = {
      patient_id: selectedPatientId,
      encounter_id: encounterId,
      set_to_close: setToClose,
    };
  
    try {
      await dispatch(closeEncounterAction(closeEncounterPayload));
      fetchAllEncounters();
    } catch (error) {
      console.error("Error updating encounter status:", error);
    }
  };  

  return (
    <Container>
      <Breadcrumb links={breadCrubLinks} />
      <HeaderWrapper>
        <CaseNumberHead>Encounters</CaseNumberHead>
        {consultationStatus === 'Ongoing' ? (
          <HeaderRight>
            <FinishButton
                    onClick={closeConsultation}
            >
              {isCloseLoading ? <Loader height={'0px'} /> : 'Finish Consultation'}
            </FinishButton>
            <AddButton
                onClick={addEncounter}
            >
              {isAddLoading ? <Loader height={'0px'} /> : 'Add Encounter'}
            </AddButton>
          </HeaderRight>
        ): null }
      </HeaderWrapper>
      {isLoading ? (
        <ShimmerTable row={5} />
      ) : (
        <>
          {encountersData?.length > 0 ? (
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  {encountersData.map((row) => {
                    const { id, start_date, status } = row;
                    return (
                      <CustomTableRow key={id}>
                        <TableCell align="left">
                          <FullNameWrapper>
                            <ProfileImage src={ILabReportThumb} alt="lab-report-thumb" />
                            <CaseNumber>Encounter {id}</CaseNumber>
                          </FullNameWrapper>
                        </TableCell>
                        <TableCell align="left">
                          <UpdatedDate>{format(parseISO(start_date), 'dd-MMM-yyyy')}</UpdatedDate>
                        </TableCell>
                        <TableCell align="left">
                          <StatusDropdown
                            value={status}
                            onChange={(event) => handleStatusChange(event, id)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            <MenuItem value="open">Open</MenuItem>
                            <MenuItem value="closed">Closed</MenuItem>
                          </StatusDropdown>
                        </TableCell>
                        <TableCell onClick={() =>
                          navigate("/encounters-health-data", {
                            state: { id: id, status: status, consultationId: consultationId },
                          })
                        }>
                          <EditDeleteWrapper>
                            <EditButton>
                              <ActionImage src={IBlueArrowRight} alt="edit" />
                            </EditButton>
                          </EditDeleteWrapper>
                        </TableCell>
                      </CustomTableRow >
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <EmptyListMessage>No encounters found</EmptyListMessage>
          )}
        </>
      )}
      <SnackBar />
    </Container>
  );
};
export default PatientEncounters;
